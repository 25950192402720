<template>
  <div
    v-if="is_initialized"
    class="admin-wrapper light-grey full-height"
    :class="{ 'no-scroll': show_nav }"
  >
    <Navigation :is-active="show_nav" @close="show_nav = false" />
    <header class="primary py-4 px-4 sticky d-flex align-center">
      <div class="flex d-flex justify-md-space-between">
        <v-btn
          icon
          class="d-md-none mr-4"
          color="white"
          @click="show_nav = true"
          ><v-icon>mdi-menu</v-icon></v-btn
        >
        <Breadcrumbs />
        <v-btn
          v-if="selected_brand"
          class="d-none d-md-flex"
          color="yellow"
          rounded
          elevation="0"
          :href="selected_brand.url"
          target="_blank"
          ><v-icon class="mr-2">mdi-eye</v-icon>Visit website</v-btn
        >
      </div>
    </header>
    <section class="px-4 pt-4">
      <transition name="fade" mode="out-in">
        <router-view />
      </transition>
    </section>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import Navigation from "./components/Navigation";
import Breadcrumbs from "./components/Breadcrumbs";

export default {
  name: "SuperAdminDashboard",
  data() {
    return {
      show_nav: false,
      is_initialized: false,
    };
  },
  components: { Breadcrumbs, Navigation },
  computed: {
    ...mapGetters("UserStore", ["name"]),
    ...mapState("UserStore", { community_admin_brand_id: "brand_id" }),
    ...mapState("SuperAdminStore", ["brands", "shops", "selected_brand"]),
  },
  async mounted() {
    window.document.title = `Admin Dashboard | ${window.document.title}`;
    if (!this.brands.length) {
      await this.get_brands();
    }
    if (!this.shops.length) {
      if (this.community_admin_brand_id)
        this.get_shops(this.community_admin_brand_id);
      else this.get_shops();
    }
    this.is_initialized = true;
  },
  methods: {
    ...mapActions("SuperAdminStore", ["get_shops", "get_brands"]),
  },
  watch: {
    show_nav: {
      handler: function () {
        if (this.$vuetify.breakpoint.mdAndUp) return;
        document.documentElement.classList.toggle("no-scroll");
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.admin-wrapper {
  @media #{map-get($display-breakpoints, 'md-and-up')} {
    display: grid;
    max-width: 100%;
    grid-template-columns: 220px minmax(0, 1fr);
    grid-template-rows: 65px auto;
  }
  header {
    grid-column: 2 / 3;
    z-index: 2;
  }
  nav {
    grid-row: 1 / 3;
  }
}

.z-10 {
  z-index: 10;
}
.border-bottom {
  border-bottom: 1px solid rgb(226, 226, 226) !important;
}
.light-grey {
  background-color: #eee;
}
a {
  font-size: 1.2rem;
  font-weight: bold;
  text-decoration: none;
  &.router-link-exact-active {
    border-bottom: 2px solid var(--v-primary-base);
  }
  &:not(.router-link-exact-active) {
    color: black;
  }
}
ul {
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  list-style-type: none;
  li {
    &:last-of-type {
      margin-right: 0;
    }
  }
}
</style>
