var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nav',{directives:[{name:"touch",rawName:"v-touch",value:({
    left: function () { return _vm.$emit('close'); },
  }),expression:"{\n    left: () => $emit('close'),\n  }"}],staticClass:"white d-flex flex-column",class:{ active: _vm.isActive }},[_c('div',{ref:"head",staticClass:"d-flex align-center justify-space-between pt-5 pb-3 px-3"},[_c('div',{staticClass:"d-flex align-center"},[_c('v-icon',{staticClass:"mr-3",attrs:{"color":"primary","size":"23"},domProps:{"textContent":_vm._s('$community-pin')}}),_c('p',{staticClass:"font-weight-bold mb-0",staticStyle:{"font-size":"1.3rem"}},[_vm._v(" Super Admin ")])],1),_c('v-btn',{staticClass:"mr-2 d-md-none",attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('close')}}},[_c('v-icon',{attrs:{"size":"25","color":"black"},domProps:{"textContent":_vm._s('$close')}})],1)],1),_c('v-list',{directives:[{name:"resize",rawName:"v-resize",value:(_vm.calcluate_max_height),expression:"calcluate_max_height"}],staticClass:"pl-0 overflow-y-auto grey-scroll-bar",staticStyle:{"flex":"1"},attrs:{"max-height":_vm.mid_list_max_height}},[(_vm.brands.length > 1)?_c('v-list-group',{attrs:{"to":{ name: 'admin-brand' },"prepend-icon":"mdi-star-outline","value":false},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-title',{staticClass:"font-weight-bold"},[_vm._v("My Brands")])]},proxy:true}],null,false,2706971147)},[_c('v-list-item',{attrs:{"to":{
          name: 'admin-brands',
        },"exact":""},on:{"click":function($event){return _vm.$emit('close')}}},[_c('v-list-item-title',{domProps:{"textContent":_vm._s('All Brands')}})],1),_vm._l((_vm.brands),function(ref,i){
        var id = ref.id;
        var name = ref.name;
return _c('v-list-item',{key:("brands_" + i),attrs:{"to":{
          name: 'admin-brands-id',
          params: { brand_id: id },
        }},on:{"click":function($event){return _vm.$emit('close')}}},[_c('v-list-item-title',{domProps:{"textContent":_vm._s(name)}})],1)})],2):(_vm.brands.length === 1)?_c('v-list-item',{attrs:{"active-class":"primary--text","to":{
        name: 'admin-brands-id',
        params: { brand_id: _vm.brands[0].id },
      }},on:{"click":function($event){return _vm.$emit('close')}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-star-outline")])],1),_c('v-list-item-title',{staticClass:"font-weight-bold",domProps:{"textContent":_vm._s(_vm.brands[0].name)}})],1):_vm._e(),_c('v-list-item',{attrs:{"to":{ name: 'admin-shops' },"active-class":"primary--text"}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-store-outline")])],1),_c('v-list-item-title',{staticClass:"font-weight-bold",domProps:{"textContent":_vm._s('My Shops')}})],1)],1),_c('v-list',{ref:"foot"},[_c('v-list-item',{attrs:{"link":"","href":"http://support.wearelocals.co.uk","target":"_blank"},on:{"click":function($event){return _vm.$emit('close')}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-help-circle-outline")])],1),_c('v-list-item-title',{staticClass:"font-weight-bold"},[_vm._v("Help")])],1),_c('v-list-item',{attrs:{"to":"/logout"},on:{"click":function($event){return _vm.$emit('close')}}},[_c('v-list-item-avatar',{attrs:{"contain":""}},[_c('Avatar',{attrs:{"variant":"beam","size":40,"colors":['#FBEAC7', '#54C4C9', '#7ACB9F', '#A582C7', '#FF8080'],"name":_vm.user.displayName}})],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"test-h5"},[_vm._v(_vm._s(_vm.user.displayName)+" ")]),_c('v-list-item-subtitle',{staticClass:"text-h6"},[_vm._v("Log Out")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }