<template>
  <nav
    class="white d-flex flex-column"
    :class="{ active: isActive }"
    v-touch="{
      left: () => $emit('close'),
    }"
  >
    <div
      class="d-flex align-center justify-space-between pt-5 pb-3 px-3"
      ref="head"
    >
      <div class="d-flex align-center">
        <v-icon
          v-text="'$community-pin'"
          color="primary"
          class="mr-3"
          size="23"
        />
        <p class="font-weight-bold mb-0" style="font-size: 1.3rem">
          Super Admin
        </p>
      </div>
      <v-btn icon class="mr-2 d-md-none" @click="$emit('close')"
        ><v-icon v-text="'$close'" size="25" color="black"
      /></v-btn>
    </div>
    <v-list
      class="pl-0 overflow-y-auto grey-scroll-bar"
      style="flex: 1"
      :max-height="mid_list_max_height"
      v-resize="calcluate_max_height"
    >
      <v-list-group
        v-if="brands.length > 1"
        :to="{ name: 'admin-brand' }"
        prepend-icon="mdi-star-outline"
        :value="false"
      >
        <template v-slot:activator>
          <v-list-item-title class="font-weight-bold"
            >My Brands</v-list-item-title
          >
        </template>
        <v-list-item
          :to="{
            name: 'admin-brands',
          }"
          exact
          @click="$emit('close')"
        >
          <v-list-item-title v-text="'All Brands'" />
        </v-list-item>
        <v-list-item
          v-for="({ id, name }, i) in brands"
          :key="`brands_${i}`"
          :to="{
            name: 'admin-brands-id',
            params: { brand_id: id },
          }"
          @click="$emit('close')"
        >
          <v-list-item-title v-text="name" />
        </v-list-item>
      </v-list-group>
      <v-list-item
        v-else-if="brands.length === 1"
        active-class="primary--text"
        :to="{
          name: 'admin-brands-id',
          params: { brand_id: brands[0].id },
        }"
        @click="$emit('close')"
      >
        <v-list-item-icon><v-icon>mdi-star-outline</v-icon></v-list-item-icon>
        <v-list-item-title class="font-weight-bold" v-text="brands[0].name" />
      </v-list-item>
      <v-list-item :to="{ name: 'admin-shops' }" active-class="primary--text">
        <v-list-item-icon><v-icon>mdi-store-outline</v-icon></v-list-item-icon>
        <v-list-item-title class="font-weight-bold" v-text="'My Shops'" />
      </v-list-item>
    </v-list>
    <v-list ref="foot">
      <v-list-item
        link
        href="http://support.wearelocals.co.uk"
        target="_blank"
        @click="$emit('close')"
      >
        <v-list-item-icon
          ><v-icon>mdi-help-circle-outline</v-icon></v-list-item-icon
        >
        <v-list-item-title class="font-weight-bold">Help</v-list-item-title>
      </v-list-item>
      <v-list-item to="/logout" @click="$emit('close')">
        <v-list-item-avatar contain>
          <Avatar
            variant="beam"
            :size="40"
            :colors="['#FBEAC7', '#54C4C9', '#7ACB9F', '#A582C7', '#FF8080']"
            :name="user.displayName"
          />
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title class="test-h5"
            >{{ user.displayName }}
          </v-list-item-title>
          <v-list-item-subtitle class="text-h6">Log Out</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </nav>
</template>

<script>
import { mapState } from "vuex";
import Avatar from "vue2-boring-avatars";

export default {
  name: "Navigation",
  components: { Avatar },
  data() {
    return {
      mid_list_max_height: 100,
    };
  },
  computed: {
    ...mapState("UserStore", ["user"]),
    ...mapState("SuperAdminStore", ["brands", "shops", "selected_brand"]),
  },
  props: {
    isActive: { type: Boolean, required: true },
  },
  mounted() {
    this.calcluate_max_height();
  },
  methods: {
    calcluate_max_height() {
      let vh = window.innerHeight;
      let head_height = this.$refs.head.offsetHeight;
      let foot_height = this.$refs.foot.$el.offsetHeight;
      this.mid_list_max_height = vh - head_height - foot_height;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .v-list-group__header__prepend-icon,
  .v-list-item__icon {
    margin-right: 0.7rem !important;
  }
}

nav {
  transition: transform 0.25s ease-in-out;
  z-index: 4;
  position: absolute;
  left: 0;
  min-height: 100vh;
  max-height: 100vh;
  transform: translateX(-100%);
  width: 100%;

  &.active {
    transform: translateX(0);
  }

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    position: sticky;
    top: 0;
    transform: unset;
  }
}
</style>
