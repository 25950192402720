<template>
  <div v-if="breadcrumbs" class="d-flex">
    <v-btn
      :class="{ 'not-shown': breadcrumbs.length === 1 }"
      icon
      dark
      :to="{ name: breadcrumbs[0].to_name }"
      ><v-icon color="white" class="rotate-90" size="28"
        >mdi-chevron-down</v-icon
      ></v-btn
    >
    <div
      class="ml-2 breadcrumbs d-flex align-center white--text"
      :class="{ 'ml-4': breadcrumbs.length > 1 }"
    >
      <component
        :is="breadcrumb.to_name ? 'router-link' : 'p'"
        class="breadcrumb mb-0"
        v-for="(breadcrumb, idx) in breadcrumbs"
        :key="`breadcrumb_${idx}`"
        :to="breadcrumb.to_name ? { name: breadcrumb.to_name } : null"
        >{{ breadcrumb_text(breadcrumb) }}</component
      >
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "Breadcrumbs",
  computed: {
    ...mapState("SuperAdminStore", ["selected_brand"]),
    ...mapState("AdminStore", ["shop"]),
    ...mapState("UserStore", ["is_community_admin"]),
    breadcrumbs() {
      let crumbs = [...this.$route.meta.breadcrumb];
      if (
        this.is_community_admin &&
        crumbs.length &&
        crumbs[0].to_name === "admin-brands"
      ) {
        crumbs.shift();
      }
      return crumbs;
    },
  },
  methods: {
    breadcrumb_text(breadcrumb) {
      if (!breadcrumb) return "";
      if (breadcrumb.name === "__selected_brand_name__")
        return this?.selected_brand?.name ?? "Loading...";
      if (breadcrumb.name === "__selected_shop_name__")
        return this?.shop?.name ?? "Loading...";
      if (breadcrumb.name.includes("__brand_zone_label__"))
        return this.selected_brand
          ? breadcrumb.name.replace(
              "__brand_zone_label__",
              this.selected_brand.zone_label
            )
          : "Loading...";
      return breadcrumb.name;
    },
  },
};
</script>

<style lang="scss" scoped>
.breadcrumbs {
  gap: 1rem;
  .breadcrumb {
    text-transform: capitalize;
    &:hover {
      color: var(--v-yellow-base) !important;
    }
    &:last-child {
      font-weight: bold;
    }
    color: white !important;
    text-decoration: none;
    font-size: 1.3rem;
    &:after {
      content: "/";
      color: white !important;
      position: relative;
      font-size: 1rem;
      left: 7px;
    }
  }
  p.breadcrumb {
    &:hover {
      color: white !important;
    }
    &:after {
      content: none;
    }
  }
}
</style>
